<template>
  <section
    style="margin-right: 5px"
    v-loading="loading"
    :style="getTableStyles"
    v-on:mouseleave="hideEdit"
  >
    <el-row>
      <div class="top-bar-container mt-1">
        <div class="float-right">
          <el-switch
            v-model="isOn"
            inactive-color="#ff4949"
            active-color="#13ce66"
            inactive-text="Table"
            active-text="Kanban"
            class="mr-1"
          >
          </el-switch>
          <el-button
            size="mini"
            type="primary"
            v-if="!isView && !isOn"
            @click="addRowToDataTable"
            >Add Data</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="!isView && isOn"
            @click="kanbanpop"
            >Add Data</el-button
          >
        </div>
      </div>
    </el-row>
    <el-row>
      <div v-loading="loading">
        <el-table
          :data="entitiesData"
          class="sidemenu"
          style="width: 98%"
          border
          stripe
          :style="getTableStyles"
          v-if="
            this.tableHeaders && this.tableHeaders.length && this.isOn == false
          "
        >
          <el-table-column
            v-for="column in tableHeaders.filter(
              (e) =>
                e.input_type !== 'DATA_TABLE' &&
                e.input_type !== 'AUTO_INCREMENT_NUMBER'
            )"
            :key="column.label"
            fixed
            style="word-break: break-word"
            :resizable="true"
            :prop="column.key"
            :label="column.label"
            :min-width="getColumnWidth(column)"
          >
            <template slot="header" slot-scope="{ column }">
              <div>
                {{ column.label }}
                <!-- <span v-if="column.validations.required" style="color: red; font-weight: bold">*</span> -->
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
                <span
                  v-if="FilterData(column).length > 0"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </div>
            </template>
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.indexKey && selectedRowIndex == scope.row.indexKey
                "
                class="field-style"
              >
                <div
                  v-if="
                    column.input_type === 'ENTITY_VARIABLE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <EntityVariableExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :hasLabel="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                    :fieldsData="tableHeaders"
                    :fromRepeatable="true"
                    @entityDataUpdated="emitDataToEntityVariables"
                    @clearEntityFields="emitUnsetEntityVariables"
                  ></EntityVariableExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'DATE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <DateExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></DateExecute>
                  <!-- <el-date-picker
                    placeholder="Select date"
                    class="smaller-than"
                    size="large"
                    v-model="selectedRow[column.key]"
                    :disabled="checkIsDisabled(column, scope.row.indexKey)"
                    :format="
                      getDefaultDateFormat &&
                      getDefaultDateFormat.includes('Do') == false
                        ? getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'
                    "
                    @change="rulesEventEmitter(scope.row.indexKey)"
                    style="width:100% !important"
                  >
                    >
                  </el-date-picker> -->
                </div>
                <div
                  v-else-if="
                    column.inputType === 'ICON' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <IconExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></IconExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'RANDOM_TEXT' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <random-text-execute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></random-text-execute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'TIME' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <TimeExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></TimeExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'CALENDER' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <calender-execute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></calender-execute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'ICON' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <icon-execute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></icon-execute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'STAR_RATING' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <StarRatingExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                    style="margin-bottom: 30px"
                  ></StarRatingExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'TIME_RANGE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <TimeRangeExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :checkIsDisabled="checkIsDisabled(column)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></TimeRangeExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'DATE_TIME' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <DateTimeExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></DateTimeExecute>
                  <!-- <el-date-picker
                    class="smaller-than"
                    size="mini"
                    prefix-icon="el-icon-date"
                    v-model="selectedRow[column.key]"
                    type="datetime"
                    placeholder="Select Date Time"
                    :format="'MM-dd-yyyy hh:mm:ss'"
                    :disabled="checkIsDisabled(column, scope.row.indexKey)"
                    @change="rulesEventEmitter(scope.row.indexKey)"
                    style="width:100% !important"
                  >
                  </el-date-picker> -->
                </div>
                <div
                  v-else-if="
                    column.inputType === 'DATE_TIME_RANGE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <DateTimeRangeExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></DateTimeRangeExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'AGGREGATE_FUNCTION' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <AggregateFunctionExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :hasLabel="hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></AggregateFunctionExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'DATE_RANGE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <DateRangeExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></DateRangeExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType == 'MULTI_SELECT' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <el-select
                    collapse-tags
                    placeholder="Please select..."
                    :clearable="true"
                    :filterable="true"
                    :multiple="
                      column.inputType == 'MULTI_SELECT' ? true : false
                    "
                    :allow-create="true"
                    :default-first-option="true"
                    v-model="selectedRow[column.key]"
                    :disabled="
                      (column.inputType == 'MULTI_SELECT' &&
                        column.options &&
                        !column.options.length) ||
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @change="rulesEventEmitter(scope.row.indexKey)"
                    style="height: 40px"
                  >
                    <el-option
                      v-for="op in column.options"
                      :key="op"
                      :label="op"
                      :value="op"
                    ></el-option>
                  </el-select>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'CHECKBOX' &&
                    !column.group_key &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <el-checkbox
                    v-model="selectedRow[column.key]"
                    :disabled="checkIsDisabled(column, scope.row.indexKey)"
                    @change="rulesEventEmitter(scope.row.indexKey)"
                  >
                  </el-checkbox>
                </div>
                <!-- <div v-else-if="column.inputType === 'CHECKBOX_GROUP' && column.group_key">
                  <CheckBoxGroupExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                  ></CheckBoxGroupExecute>
                </div> -->
                <div
                  v-else-if="
                    column.inputType == 'CURRENCY' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <CurrencyExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></CurrencyExecute>
                </div>
                <div
                  v-else-if="
                    (column.inputType == 'SINGLE_LINE_TEXT' ||
                      column.inputTyp === 'EMAIL' ||
                      column.inputTyp === 'PASSWORD' ||
                      column.inputType === 'MASKED' ||
                      column.inputType === 'HYPER_LINK') &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <SingleLineTextExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                    style="height: 40px"
                  ></SingleLineTextExecute>
                </div>
                <div
                  v-else-if="
                    (column.inputType == 'NUMBER' ||
                      column.inputType == 'PHONE' ||
                      column.inputType == 'ZIP_CODE' ||
                      column.inputType == 'FAX') &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <NumberExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                    style="height: 40px"
                  ></NumberExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType == 'MULTI_LINE_TEXT' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <MultiLineTextExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></MultiLineTextExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType === 'SELECT' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <SelectExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></SelectExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'CONCATENATE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <ConcatenateExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></ConcatenateExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType === 'LIST' &&
                    scope.row.indexKey &&
                    !checkhideFields(column)
                  "
                >
                  <ListExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></ListExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType == 'FORMULA' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <FormulaExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></FormulaExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'ENTITY' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <EntityExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :fieldsData="tableHeaders"
                    :fromRepeatable="true"
                    @entityDataUpdated="emitDataToEntityVariables"
                    @clearEntityFields="emitUnsetEntityVariables"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></EntityExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'CHECKBOX_GROUP' &&
                    scope.row.indexKey &&
                    !checkhideFields(column)
                  "
                >
                  <CheckBoxGroupExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :isView="false"
                    :fieldsData="tableHeaders"
                    :hasLabel="hasLabel"
                  ></CheckBoxGroupExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'RADIO_BUTTON_GROUP' &&
                    !checkhideFields(column)
                  "
                >
                  <RadioButtonGroupExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :isView="false"
                    :fieldsData="tableHeaders"
                    :hasLabel="!hasLabel"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></RadioButtonGroupExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'RADIO' && !checkhideFields(column)
                  "
                >
                  <RadioExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></RadioExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'QUESTION' && !checkhideFields(column)
                  "
                >
                  <QuestionExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :hasLabel="hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                  ></QuestionExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'PHONE_COUNTRY_CODE' &&
                    !checkhideFields(column)
                  "
                >
                  <PhoneCountryCodeExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></PhoneCountryCodeExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'SIGNATURE' && !checkhideFields(column)
                  "
                >
                  <ESignatureExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                  ></ESignatureExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'YES_OR_NO' && !checkhideFields(column)
                  "
                >
                  <YesOrNoExecute
                    :data="column"
                    :form="selectedRow"
                    :fromRepeatable="true"
                    :is-view="false"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                  ></YesOrNoExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'FILE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column)
                  "
                >
                  <FileExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="!hasLabel"
                    :isFromRepeatable="true"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></FileExecute>
                </div>
                <div
                  v-else-if="column.inputType == 'CHECKBOX' && column.group_key"
                >
                  <CheckBoxExecute
                    :data="column"
                    :form="selectedRow"
                    :isView="false"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="hasLabel"
                    :singleCompanyTemplateCheckBoxArray="
                      singleCompanyTemplateCheckBoxArray
                    "
                  ></CheckBoxExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'WEEKDAYS' &&
                    scope.row.indexKey &&
                    !checkhideFields(column)
                  "
                >
                  <WeekDaysExecute
                    :data="column"
                    :form="selectedRow"
                    :hasLabel="false"
                    :is-view="false"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                    style="width: 100%; height: 100%"
                  >
                  </WeekDaysExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'AGGREGATE_FUNCTION' &&
                    scope.row.indexKey &&
                    !checkhideFields(column)
                  "
                >
                  <AggregateFunctionExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="!hasLabel"
                    :isFromRepeatable="true"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></AggregateFunctionExecute>
                </div>

                <div
                  v-else-if="
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <el-input
                    type="text"
                    placeholder="Enter value"
                    v-model="selectedRow[column.key]"
                    size="mini"
                  ></el-input>
                </div>
              </div>
              <div
                v-else-if="
                  scope.row[column.key] ||
                  scope.row[column.key] == true ||
                  scope.row[column.key] == false
                "
              >
                <p v-if="column.inputType == 'DATE'">
                  {{ scope.row[column.key] | moment("MM-DD-YYYY") }}
                </p>
                <p v-else-if="column.inputType == 'DATE_TIME'">
                  {{ scope.row[column.key] | moment("MM-DD-YYYY HH:mm:ss") }}
                </p>
                <p v-else-if="column.inputType == 'DATE_TIME_RANGE'">
                  <span v-if="scope.row[column.key].includes(',')">
                    {{
                      scope.row[column.key].split(",")[0] | globalDateTimeFormat
                    }}
                    (From ~ To)
                    {{
                      scope.row[column.key].split(",")[1] | globalDateTimeFormat
                    }}
                  </span>
                  <span v-else-if="scope.row[column.key].length">
                    {{ scope.row[column.key][0] | globalDateTimeFormat }} (From
                    ~ To) {{ scope.row[column.key][1] | globalDateTimeFormat }}
                  </span>
                  <span v-else>
                    {{
                      scope.row[`${column.key}_from`]
                        | moment("MM-DD-YYYY HH:mm:ss")
                    }}
                    (From ~ To)
                    {{
                      scope.row[`${column.key}_to`]
                        | moment("MM-DD-YYYY HH:mm:ss")
                    }}
                  </span>
                </p>
                <p v-else-if="column.inputType == 'DATE_RANGE'">
                  <span v-if="scope.row[column.key].includes(',')">
                    {{ scope.row[column.key].split(",")[0] | globalDateFormat }}
                    (From ~ To)
                    {{ scope.row[column.key].split(",")[1] | globalDateFormat }}
                  </span>
                  <span v-else-if="scope.row[column.key].length">
                    {{ scope.row[column.key][0] | globalDateFormat }} (From ~
                    To) {{ scope.row[column.key][1] | globalDateFormat }}
                  </span>
                  <span v-else>
                    {{ scope.row[`${column.key}_from`] | moment("MM-DD-YYYY") }}
                    (From ~ To)
                    {{ scope.row[`${column.key}_to`] | moment("MM-DD-YYYY") }}
                  </span>
                </p>
                <p v-else-if="column.inputType == 'MULTI_SELECT'">
                  {{ scope.row[column.key].join(", ") }}
                </p>
                <!-- <p v-else-if="column.inputType == 'MULTI_SELECT'">
                {{ scope.row[column.key].join(", ") }}
              </p> -->
                <div
                  v-else-if="
                    column.inputType == 'CURRENCY' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <CurrencyExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></CurrencyExecute>
                </div>
                <div
                  v-else-if="
                    (column.inputType == 'SINGLE_LINE_TEXT' ||
                      column.inputTyp === 'EMAIL' ||
                      column.inputTyp === 'PASSWORD' ||
                      column.inputType === 'MASKED' ||
                      column.inputType === 'HYPER_LINK') &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <SingleLineTextExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></SingleLineTextExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'CONCATENATE' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <ConcatenateExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></ConcatenateExecute>
                </div>

                <!-- <div
                  v-else-if="
                    column.inputType == 'MULTI_SELECT' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <MultiSelectExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></MultiSelectExecute>
                </div> -->

                <div
                  v-else-if="
                    (column.inputType == 'NUMBER' ||
                      column.inputType == 'PHONE' ||
                      column.inputType == 'ZIP_CODE' ||
                      column.inputType == 'FAX') &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <NumberExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></NumberExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType == 'MULTI_LINE_TEXT' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <MultiLineTextExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></MultiLineTextExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType === 'SELECT' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <SelectExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                    style="width: 100%; height: 100%"
                  ></SelectExecute>
                </div>

                <div
                  v-else-if="
                    column.inputType === 'LIST' &&
                    scope.row.indexKey &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <ListExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></ListExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'FORMULA' &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <FormulaExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></FormulaExecute>
                </div>
                <div v-else-if="column.inputType == 'ENTITY'">
                  <EntityExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :fromRepeatable="true"
                    @entityDataUpdated="emitDataToEntityVariables"
                    @clearEntityFields="emitUnsetEntityVariables"
                  ></EntityExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'FILE' &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <FileExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="!hasLabel"
                    :isFromRepeatable="true"
                    style="margin-top: 20px"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></FileExecute>
                </div>
                <div v-else-if="column.inputType == 'CHECKBOX_GROUP'">
                  <CheckBoxGroupExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="hasLabel"
                    :singleCompanyTemplateCheckBoxArray="
                      singleCompanyTemplateCheckBoxArray
                    "
                  ></CheckBoxGroupExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'RADIO_BUTTON_GROUP' &&
                    !checkhideFields(column)
                  "
                >
                  <RadioButtonGroupExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="!hasLabel"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></RadioButtonGroupExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'RADIO' && !checkhideFields(column)
                  "
                >
                  <RadioExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                  ></RadioExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'AUTO_INCREMENT_NUMBER' &&
                    !checkhideFields(column)
                  "
                >
                  <AutoIncrementExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></AutoIncrementExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'QUESTION' && !checkhideFields(column)
                  "
                >
                  <QuestionExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :hasLabel="hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                  ></QuestionExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'SIGNATURE' && !checkhideFields(column)
                  "
                >
                  <ESignatureExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></ESignatureExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'PHONE_COUNTRY_CODE' &&
                    !checkhideFields(column)
                  "
                >
                  <PhoneCountryCodeExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></PhoneCountryCodeExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType === 'YES_OR_NO' && !checkhideFields(column)
                  "
                >
                  <YesOrNoExecute
                    :data="column"
                    :form="scope.row"
                    :fromRepeatable="true"
                    :is-view="true"
                    :hasLabel="!hasLabel"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                  ></YesOrNoExecute>
                </div>
                <div
                  v-else-if="column.inputType == 'CHECKBOX' && column.group_key"
                >
                  <CheckBoxExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="hasLabel"
                    :singleCompanyTemplateCheckBoxArray="
                      singleCompanyTemplateCheckBoxArray
                    "
                  ></CheckBoxExecute>
                </div>
                <div v-else-if="column.inputType == 'WEEKDAYS'">
                  <h5 class="kanban-card-content"></h5>
                  <WeekDaysExecute
                    :data="column"
                    :form="scope.row"
                    :hasLabel="false"
                    :is-view="true"
                    :style="{ width: '100%' }"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  >
                  </WeekDaysExecute>
                </div>
                <div v-else-if="column.inputType === 'AGGREGATE_FUNCTION'">
                  <AggregateFunctionExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="hasLabel"
                  ></AggregateFunctionExecute>
                </div>
                <div
                  v-else-if="
                    column.inputType == 'TIME' &&
                    !checkhideFields(column, scope.row.indexKey)
                  "
                >
                  <TimeExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :checkIsDisabled="
                      checkIsDisabled(column, scope.row.indexKey)
                    "
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  ></TimeExecute>
                </div>
                <div v-else-if="column.inputType == 'TIME_RANGE'">
                  <TimeRangeExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                  ></TimeRangeExecute>
                </div>
                <p v-else>{{ scope.row[column.key] }}</p>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column
            fixed
            label="Actions"
            :min-width="120"
            v-if="!isView"
          >
            <template slot-scope="scope">
              <el-button class="p-0" round>
                <img
                  src="@/assets/img/icons/create.svg"
                  alt="icon"
                  @click="onEdit(scope.row)"
                  width="15"
                />
              </el-button>
              <el-button class="p-0" round>
                <img
                  src="@/assets/img/icons/delete.png"
                  alt="icon"
                  @click="onDelete(scope.row)"
                  width="15"
                />
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="this.isOn == true">
          <div class="kanban-cards">
            <el-card
              class="kanban-card"
              shadow="hover"
              v-for="(entity, index) in entitiesData"
              :key="index"
            >
              <el-scrollbar
                wrap-style="max-height: 400px;"
                style="border-width: 0px"
              >
                <div
                  :style="
                    kanban_index == index
                      ? ''
                      : 'display: grid; grid-template-columns: 50% 50%'
                  "
                >
                  <div
                    class="kanban-card-column"
                    v-for="column in tableHeaders"
                    :key="column.label"
                    :prop="column.key"
                  >
                    <div v-if="kanban_index == index">
                      <div
                        v-if="
                          column.input_type === 'ENTITY_VARIABLE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <EntityVariableExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="checkIsDisabled(column)"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                          :fieldsData="tableHeaders"
                          :fromRepeatable="true"
                          @entityDataUpdated="emitDataToEntityVariables"
                          @clearEntityFields="emitUnsetEntityVariables"
                        ></EntityVariableExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'DATE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">
                          {{ column.label }}
                          <!-- {{ column.validations.required }} -->
                          <span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <el-date-picker
                          placeholder="Select date"
                          class="smaller-than"
                          size="large"
                          v-model="selectedRow[column.key]"
                          :format="
                            getDefaultDateFormat &&
                            getDefaultDateFormat.includes('Do') == false
                              ? getDefaultDateFormat
                                  .replace('YYYY', 'yyyy')
                                  .replace('DD', 'dd')
                              : 'MM-dd-yyyy'
                          "
                          :disabled="checkIsDisabled(column, entity.indexKey)"
                          :style="{ width: '100%' }"
                          @change="rulesEventEmitter(entity.indexKey)"
                        >
                        </el-date-picker>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'TIME' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <!-- <h5 class="kanban-card-content">{{ column.label }}</h5> -->
                        <TimeExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="checkIsDisabled(column)"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></TimeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'TIME_RANGE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <!-- <h5 class="kanban-card-content">{{ column.label }}</h5> -->
                        <TimeRangeExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="checkIsDisabled(column)"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></TimeRangeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'DATE_TIME' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">
                          {{ column.label
                          }}<span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <el-date-picker
                          class="smaller-than"
                          size="large"
                          prefix-icon="el-icon-date"
                          v-model="selectedRow[column.key]"
                          type="datetime"
                          placeholder="Select Date Time"
                          :format="'MM-dd-yyyy hh:mm:ss'"
                          :disabled="checkIsDisabled(column, entity.indexKey)"
                          :style="{ width: '100%' }"
                          @change="rulesEventEmitter(entity.indexKey)"
                        >
                        </el-date-picker>
                      </div>

                      <div
                        v-else-if="
                          column.inputType === 'DATE_TIME_RANGE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">
                          {{ column.label
                          }}<span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <DateTimeRangeExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :style="{ width: '100%' }"
                          :fieldsData="tableHeaders"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></DateTimeRangeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'DATE_RANGE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <DateRangeExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :style="{ width: '100%' }"
                          :fieldsData="tableHeaders"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></DateRangeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'MULTI_SELECT' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <el-select
                          collapse-tags
                          placeholder="Please select..."
                          :clearable="true"
                          :filterable="true"
                          :multiple="
                            column.inputType == 'MULTI_SELECT' ? true : false
                          "
                          :allow-create="true"
                          :default-first-option="true"
                          v-model="selectedRow[column.key]"
                          :disabled="
                            (column.inputType === 'MULTI_SELECT' &&
                              (!column.options || !column.options.length)) ||
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @change="rulesEventEmitter(entity.indexKey)"
                          :style="{ width: '100%' }"
                        >
                          <el-option
                            v-for="op in column.options"
                            :key="op"
                            :label="op"
                            :value="op"
                          ></el-option>
                        </el-select>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'CHECKBOX' && !column.group_key
                        "
                      >
                        <h5 class="kanban-card-content">
                          {{ column.label
                          }}<span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <el-checkbox v-model="selectedRow[column.key]">
                        </el-checkbox>
                      </div>
                      <!-- <div v-else-if="column.inputType === 'CHECKBOX_GROUP' && column.group_key">
                <CheckBoxGroupExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                ></CheckBoxGroupExecute>
              </div> -->
                      <div
                        v-else-if="
                          column.inputType == 'CURRENCY' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">
                          {{ column.label }}
                          <span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <CurrencyExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :style="{ width: '100%' }"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></CurrencyExecute>
                      </div>
                      <div
                        v-else-if="
                          (column.inputType == 'SINGLE_LINE_TEXT' ||
                            column.inputTyp === 'EMAIL' ||
                            column.inputTyp === 'PASSWORD' ||
                            column.inputType === 'MASKED' ||
                            column.inputType === 'HYPER_LINK') &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content"></h5>
                        <SingleLineTextExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :isView="false"
                          :hasLabel="hasLabel"
                          :style="{ width: '78%' }"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></SingleLineTextExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'FORMULA' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <FormulaExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></FormulaExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'ENTITY' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <EntityExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :fromRepeatable="true"
                          :style="{ width: '100%' }"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                          @entityDataUpdated="emitDataToEntityVariables"
                          @clearEntityFields="emitUnsetEntityVariables"
                        ></EntityExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'CHECKBOX_GROUP' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <CheckBoxGroupExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></CheckBoxGroupExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'RADIO_BUTTON_GROUP' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">
                          {{ column.label }}
                          <span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <RadioButtonGroupExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :hasLabel="!hasLabel"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></RadioButtonGroupExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'RADIO' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <RadioExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :hasLabel="!hasLabel"
                          :fieldsData="tableHeaders"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></RadioExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'AUTO_INCREMENT_NUMBER' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <AutoIncrementExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :fieldsData="tableHeaders"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></AutoIncrementExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'QUESTION' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <QuestionExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :hasLabel="!hasLabel"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></QuestionExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'PHONE_COUNTRY_CODE' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <PhoneCountryCodeExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></PhoneCountryCodeExecute>
                      </div>
                      <div v-else-if="column.inputType === 'SIGNATURE'">
                        <ESignatureExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :fieldsData="tableHeaders"
                        ></ESignatureExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'YES_OR_NO' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <YesOrNoExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :fieldsData="tableHeaders"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></YesOrNoExecute>
                      </div>
                      <div v-else-if="column.inputType == 'FILE'">
                        <h5 class="kanban-card-content">
                          {{ column.label }}
                          <span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <FileExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="!hasLabel"
                          :isFromRepeatable="true"
                        ></FileExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'CHECKBOX' &&
                          column.group_key &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <CheckBoxExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :style="{ width: '78%' }"
                          :hasLabel="hasLabel"
                          :singleCompanyTemplateCheckBoxArray="
                            singleCompanyTemplateCheckBoxArray
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                        ></CheckBoxExecute>
                      </div>
                      <div v-else-if="column.inputType == 'STAR_RATING'">
                        <StarRatingExecute
                          :data="column"
                          :form="selectedRow"
                          :hasLabel="hasLabel"
                          :is-view="false"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                        ></StarRatingExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'WEEKDAYS' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <WeekDaysExecute
                          :data="column"
                          :form="selectedRow"
                          :hasLabel="hasLabel"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                        >
                        </WeekDaysExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'SELECT' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <SelectExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          :style="{ width: '100%' }"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        >
                        </SelectExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'AGGREGATE_FUNCTION' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <AggregateFunctionExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                        ></AggregateFunctionExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'LIST' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <ListExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          :style="{ width: '100%' }"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></ListExecute>
                      </div>
                      <div
                        v-else-if="
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">
                          {{ column.label }}
                          <span
                            v-if="column.validations.required"
                            style="color: red; font-weight: bold"
                            >*</span
                          >
                        </h5>
                        <el-input
                          type="text"
                          v-model="selectedRow[column.key]"
                          style="width: 22vw"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          :style="{ width: '100%' }"
                          :disabled="checkIsDisabled(column, entity.indexKey)"
                          @change="rulesEventEmitter(entity.indexKey)"
                        ></el-input>
                      </div>
                    </div>
                    <div v-else>
                      <h5 class="kanban-card-content kanban-card-content-color">
                        {{ column.label }}
                        <!-- {{ column.validations.required }} -->
                        <span
                          v-if="column.validations.required"
                          style="color: red; font-weight: bold"
                          >*</span
                        >
                      </h5>
                      <p v-if="column.inputType == 'DATE'">
                        {{ entity[column.key] | moment("MM-DD-YYYY") }}
                      </p>
                      <p v-else-if="column.inputType == 'DATE_TIME'">
                        {{ entity[column.key] | moment("MM-DD-YYYY HH:mm:ss") }}
                      </p>
                      <p v-else-if="column.inputType == 'DATE_TIME_RANGE'">
                        <span v-if="entity[column.key].includes(',')">
                          {{
                            entity[column.key].split(",")[0]
                              | globalDateTimeFormat
                          }}
                          (From ~ To)
                          {{
                            entity[column.key].split(",")[1]
                              | globalDateTimeFormat
                          }}
                        </span>
                        <span v-else-if="entity[column.key].length">
                          {{ entity[column.key][0] | globalDateTimeFormat }}
                          (From ~ To)
                          {{ entity[column.key][1] | globalDateTimeFormat }}
                        </span>
                        <span v-else>
                          {{
                            entity[`${column.key}_from`]
                              | moment("MM-DD-YYYY HH:mm:ss")
                          }}
                          (From ~ To)
                          {{
                            entity[`${column.key}_to`]
                              | moment("MM-DD-YYYY HH:mm:ss")
                          }}
                        </span>
                      </p>
                      <p v-else-if="column.inputType == 'DATE_RANGE'">
                        <span v-if="entity[column.key].includes(',')">
                          {{
                            entity[column.key].split(",")[0] | globalDateFormat
                          }}
                          (From ~ To)
                          {{
                            entity[column.key].split(",")[1] | globalDateFormat
                          }}
                        </span>
                        <span v-else-if="entity[column.key].length">
                          {{ entity[column.key][0] | globalDateFormat }} (From ~
                          To) {{ entity[column.key][1] | globalDateFormat }}
                        </span>
                        <span v-else>
                          {{
                            entity[`${column.key}_from`] | moment("MM-DD-YYYY")
                          }}
                          (From ~ To)
                          {{
                            entity[`${column.key}_to`] | moment("MM-DD-YYYY")
                          }}
                        </span>
                      </p>
                      <p v-else-if="column.inputType == 'MULTI_SELECT'">
                        {{ (entity[column.key] || []).join(", ") }}
                      </p>
                      <div v-else-if="column.inputType == 'CURRENCY'">
                        <CurrencyExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></CurrencyExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'SINGLE_LINE_TEXT' ||
                          column.inputTyp === 'EMAIL' ||
                          column.inputTyp === 'PASSWORD' ||
                          column.inputType === 'MASKED' ||
                          column.inputType === 'HYPER_LINK'
                        "
                      >
                        <SingleLineTextExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></SingleLineTextExecute>
                      </div>
                      <!-- <div v-else-if="column.inputType == 'MULTI_SELECT'">
                
                <MultiSelectExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                 
                  :fieldsData="tableHeaders"
                ></MultiSelectExecute>
              </div> -->

                      <div
                        v-else-if="
                          column.inputType == 'NUMBER' ||
                          column.inputType == 'PHONE' ||
                          column.inputType == 'ZIP_CODE' ||
                          column.inputType == 'FAX'
                        "
                      >
                        <NumberExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></NumberExecute>
                      </div>

                      <div v-else-if="column.inputType == 'MULTI_LINE_TEXT'">
                        <MultiLineTextExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></MultiLineTextExecute>
                      </div>

                      <div v-else-if="column.inputType === 'SELECT'">
                        <SelectExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></SelectExecute>
                      </div>
                      <div v-else-if="column.inputType === 'LIST'">
                        <ListExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></ListExecute>
                      </div>
                      <div v-else-if="column.inputType == 'ENTITY'">
                        <EntityExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                          :fromRepeatable="true"
                          @entityDataUpdated="emitDataToEntityVariables"
                          @clearEntityFields="emitUnsetEntityVariables"
                        ></EntityExecute>
                      </div>
                      <div v-else-if="column.inputType == 'FILE'">
                        <FileExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="!hasLabel"
                          :isFromRepeatable="true"
                        ></FileExecute>
                      </div>
                      <div v-else-if="column.inputType == 'CHECKBOX'">
                        <CheckBoxExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :singleCompanyTemplateCheckBoxArray="
                            singleCompanyTemplateCheckBoxArray
                          "
                        ></CheckBoxExecute>
                      </div>
                      <div v-else-if="column.inputType == 'STAR_RATING'">
                        <StarRatingExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="!hasLabel"
                          :singleCompanyTemplateCheckBoxArray="
                            singleCompanyTemplateCheckBoxArray
                          "
                        ></StarRatingExecute>
                      </div>
                      <div v-else-if="column.inputType == 'CONCATENATE'">
                        <ConcatenateExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></ConcatenateExecute>
                      </div>
                      <div v-else-if="column.inputType == 'WEEKDAYS'">
                        <WeekDaysExecute
                          :data="column"
                          :form="entity"
                          :hasLabel="false"
                          :is-view="true"
                        >
                        </WeekDaysExecute>
                      </div>
                      <div v-else-if="column.inputType == 'CHECKBOX_GROUP'">
                        <CheckBoxGroupExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :singleCompanyTemplateCheckBoxArray="
                            singleCompanyTemplateCheckBoxArray
                          "
                        ></CheckBoxGroupExecute>
                      </div>
                      <div
                        v-else-if="column.inputType === 'RADIO_BUTTON_GROUP'"
                      >
                        <RadioButtonGroupExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="!hasLabel"
                        ></RadioButtonGroupExecute>
                      </div>
                      <div v-else-if="column.inputType === 'RADIO'">
                        <RadioExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :hasLabel="!hasLabel"
                          :fieldsData="tableHeaders"
                        ></RadioExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'AUTO_INCREMENT_NUMBER' &&
                          !checkhideFields(column)
                        "
                      >
                        <AutoIncrementExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :hasLabel="!hasLabel"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></AutoIncrementExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'QUESTION' &&
                          !checkhideFields(column)
                        "
                      >
                        <QuestionExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :hasLabel="hasLabel"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></QuestionExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'PHONE_COUNTRY_CODE' &&
                          !checkhideFields(column)
                        "
                      >
                        <PhoneCountryCodeExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :hasLabel="!hasLabel"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></PhoneCountryCodeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'SIGNATURE' &&
                          !checkhideFields(column)
                        "
                      >
                        <ESignatureExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="false"
                          :hasLabel="!hasLabel"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></ESignatureExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'YES_OR_NO' &&
                          !checkhideFields(column)
                        "
                      >
                        <YesOrNoExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :hasLabel="!hasLabel"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></YesOrNoExecute>
                      </div>
                      <div v-else-if="column.inputType == 'FORMULA'">
                        <FormulaExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></FormulaExecute>
                      </div>
                      <div v-else-if="column.inputType == 'TIME'">
                        <TimeExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></TimeExecute>
                      </div>
                      <div v-else-if="column.inputType == 'TIME_RANGE'">
                        <TimeRangeExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                        ></TimeRangeExecute>
                      </div>
                      <div
                        v-else-if="column.inputType === 'AGGREGATE_FUNCTION'"
                      >
                        <AggregateFunctionExecute
                          :data="column"
                          :form="entity"
                          :is-view="false"
                        ></AggregateFunctionExecute>
                      </div>
                      <p v-else>{{ entity[column.key] || "-" }}</p>

                      <!-- <p class="kanban-card-content" v-if="entity[column.key]">{{entity[column.key]}}</p>
                            <p v-else>-</p> -->
                    </div>
                  </div>
                </div>
              </el-scrollbar>
              <div class="kanban-footer" v-if="!isView">
                <el-button
                  type="text"
                  v-if="kanban_index == index"
                  @click="saveChanges"
                  style="color: #67c23a"
                  >Save
                </el-button>
                <el-button
                  type="text"
                  v-if="kanban_index == index"
                  @click="cancelChanges"
                  >Cancel
                </el-button>
                <el-button
                  type="text"
                  v-if="kanban_index != index"
                  @click="editKanban(index)"
                  >Edit</el-button
                >
                <el-button
                  class="kanban-footer-button"
                  type="text"
                  @click="deleteKanban(entity)"
                  v-if="kanban_index != index"
                  >Delete</el-button
                >
              </div>
            </el-card>
          </div>
        </div>
        <!-- ADD DATA MODE OPEN -->
        <dialog-component
          :title="'Add ' + templateData.name"
          :visible="kanbanDialog"
          @before-close="closeDialog"
          containerWidth="40%"
          containerHeight="70%"
          id="add-an-element-popup"
        >
          <div
            class="d-flex flex-column justify-content-center"
            v-for="column in tableHeaders"
            :key="column.label"
            :prop="column.key"
          >
            <div
              v-if="
                column.input_type === 'ENTITY_VARIABLE' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <EntityVariableExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
                :hasLabel="hasLabel"
                :fieldsData="tableHeaders"
                :fromRepeatable="true"
                @entityDataUpdated="emitDataToEntityVariables"
                @clearEntityFields="emitUnsetEntityVariables"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></EntityVariableExecute>
            </div>
            <div
              v-else-if="
                column.inputType == 'DATE' &&
                !checkhideFields(selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <el-date-picker
                placeholder="Select date"
                class="smaller-than"
                size="large"
                v-model="selectedRow[column.key]"
                :format="
                  getDefaultDateFormat &&
                  getDefaultDateFormat.includes('Do') == false
                    ? getDefaultDateFormat
                        .replace('YYYY', 'yyyy')
                        .replace('DD', 'dd')
                    : 'MM-dd-yyyy'
                "
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              >
              </el-date-picker>
            </div>
            <div
              v-else-if="
                column.inputType === 'TIME' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <TimeExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></TimeExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'TIME_RANGE' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <TimeRangeExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></TimeRangeExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'DATE_TIME' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <el-date-picker
                class="smaller-than"
                size="mini"
                prefix-icon="el-icon-date"
                v-model="selectedRow[column.key]"
                type="datetime"
                placeholder="Select Date Time"
                :format="'MM-dd-yyyy HH:mm:ss'"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              >
              </el-date-picker>
            </div>

            <div
              v-else-if="
                column.inputType === 'DATE_TIME_RANGE' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <DateTimeRangeExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></DateTimeRangeExecute>
              <!-- <el-date-picker
                class="smaller-than"
                size="mini"
                prefix-icon="el-icon-date"
                v-model="selectedRow[column.key]"
                type="datetimerange"
                 range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                popper-class="my-popover"
                placeholder="Select Date Time"
                :format="'MM-dd-yyyy hh:mm:ss'"
              >
              </el-date-picker> -->
            </div>
            <div
              v-else-if="
                column.inputType === 'DATE_RANGE' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <DateRangeExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></DateRangeExecute>
            </div>
            <div
              v-else-if="
                column.inputType == 'MULTI_SELECT' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <el-select
                collapse-tags
                placeholder="Please select..."
                :clearable="true"
                :filterable="true"
                :style="{ width: '100%' }"
                :multiple="column.inputType == 'MULTI_SELECT' ? true : false"
                :allow-create="true"
                :default-first-option="true"
                v-model="selectedRow[column.key]"
                :disabled="
                  column.inputType == 'MULTI_SELECT' &&
                  column.options &&
                  !column.options.length
                "
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              >
                <el-option
                  v-for="op in column.options"
                  :key="op"
                  :label="op"
                  :value="op"
                ></el-option>
              </el-select>
            </div>
            <div
              v-else-if="column.inputType == 'CHECKBOX' && !column.group_key"
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <el-checkbox v-model="selectedRow[column.key]"> </el-checkbox>
            </div>

            <div
              v-else-if="
                column.inputType == 'ENTITY' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <EntityExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
                :fieldsData="tableHeaders"
                :fromRepeatable="true"
                :hasLabel="!hasLabel"
                @entityDataUpdated="emitDataToEntityVariables"
                @clearEntityFields="emitUnsetEntityVariables"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></EntityExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'RADIO_BUTTON_GROUP' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <RadioButtonGroupExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :fieldsData="tableHeaders"
                :hasLabel="!hasLabel"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></RadioButtonGroupExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'RADIO' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <RadioExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :hasLabel="hasLabel"
                :fieldsData="tableHeaders"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></RadioExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'AUTO_INCREMENT_NUMBER' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <AutoIncrementExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :fieldsData="tableHeaders"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></AutoIncrementExecute>
            </div>
            <div v-else-if="column.inputType === 'QUESTION'">
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <QuestionExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :hasLabel="hasLabel"
                :fieldsData="tableHeaders"
              ></QuestionExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'PHONE_COUNTRY_CODE' &&
                !checkhideFields(column)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <PhoneCountryCodeExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :hasLabel="!hasLabel"
                :fieldsData="tableHeaders"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></PhoneCountryCodeExecute>
            </div>
            <div v-else-if="column.inputType === 'SIGNATURE'">
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>

              <ESignatureExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :hasLabel="!hasLabel"
                :fieldsData="tableHeaders"
              ></ESignatureExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'YES_OR_NO' && !checkhideFields(column)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <YesOrNoExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :hasLabel="!hasLabel"
                :fieldsData="tableHeaders"
              ></YesOrNoExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'CHECKBOX_GROUP' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <CheckBoxGroupExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :fieldsData="tableHeaders"
                :hasLabel="hasLabel"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></CheckBoxGroupExecute>
            </div>
            <div v-else-if="column.inputType == 'FILE'">
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <FileExecute
                :data="column"
                :form="selectedRow"
                :isView="false"
                :fromRepeatable="true"
                :fieldsData="tableHeaders"
                :hasLabel="!hasLabel"
                :isFromRepeatable="true"
              ></FileExecute>
            </div>
            <div
              v-else-if="
                column.inputType == 'CHECKBOX' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <CheckBoxExecute
                :data="column"
                :form="selectedRow"
                :isView="false"
                :fromRepeatable="true"
                :fieldsData="tableHeaders"
                :hasLabel="hasLabel"
                :style="{ width: '78%' }"
                :singleCompanyTemplateCheckBoxArray="
                  singleCompanyTemplateCheckBoxArray
                "
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></CheckBoxExecute>
            </div>
            <div
              v-else-if="
                column.inputType == 'WEEKDAYS' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <WeekDaysExecute
                :data="column"
                :form="selectedRow"
                :hasLabel="hasLabel"
                :is-view="false"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              >
              </WeekDaysExecute>
            </div>
            <div v-else-if="column.inputType == 'STAR_RATING'">
              <StarRatingExecute
                :data="column"
                :form="selectedRow"
                :hasLabel="hasLabel"
                :is-view="false"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></StarRatingExecute>
            </div>
            <div
              v-else-if="
                column.inputType == 'CURRENCY' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <!-- <h5 class="kanban-card-content">{{ column.label }}
                  <span v-if="isRequiredFiled(column)" style="color: red; font-weight: bold">*</span></h5> -->
              <CurrencyExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :hasLabel="hasLabel"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></CurrencyExecute>
            </div>
            <div
              v-else-if="
                (column.inputType == 'SINGLE_LINE_TEXT' ||
                  column.inputTyp === 'EMAIL' ||
                  column.inputTyp === 'PASSWORD' ||
                  column.inputType === 'MASKED' ||
                  column.inputType === 'HYPER_LINK') &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content"></h5>
              <SingleLineTextExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :hasLabel="hasLabel"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></SingleLineTextExecute>
            </div>
            <!-- <div v-else-if="column.inputType == 'MULTI_SELECT'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <MultiSelectExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :isView="false"
                ></MultiSelectExecute>
            </div> -->

            <div
              v-else-if="
                column.inputType == 'NUMBER' ||
                column.inputType == 'PHONE' ||
                column.inputType == 'ZIP_CODE' ||
                (column.inputType == 'FAX' &&
                  !checkhideFields(column, selectedRow.indexKey))
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <NumberExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></NumberExecute>
            </div>

            <div
              v-else-if="
                column.inputType == 'MULTI_LINE_TEXT' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <MultiLineTextExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></MultiLineTextExecute>
            </div>

            <div
              v-else-if="
                column.inputType === 'SELECT' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <!-- {{ selectedRow[column.key] }} -->
              <SelectExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></SelectExecute>
            </div>

            <div
              v-else-if="
                column.inputType === 'LIST' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <ListExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></ListExecute>
            </div>
            <div
              v-else-if="
                column.inputType == 'FORMULA' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <FormulaExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></FormulaExecute>
            </div>
            <div
              v-else-if="
                column.inputType === 'AGGREGATE_FUNCTION' &&
                !checkhideFields(column, selectedRow.indexKey)
              "
            >
              <h5 class="kanban-card-content">
                {{ column.label }}
                <span
                  v-if="isRequiredFiled(column)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </h5>
              <AggregateFunctionExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :style="{ width: '100%' }"
                :checkIsDisabled="checkIsDisabled(column, selectedRow.indexKey)"
                @applyFormRules="rulesEventEmitter(selectedRow.indexKey)"
              ></AggregateFunctionExecute>
            </div>
            <div v-else-if="!checkhideFields(column, selectedRow.indexKey)">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <el-input
                type="text"
                v-model="selectedRow[column.key]"
              ></el-input>
            </div>
            <br />
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="kanbanDialog = false">Cancel</el-button>
            <el-button type="primary" @click="kabanAdd">Add</el-button>
          </span>
        </dialog-component>
      </div>
    </el-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
// import moment from "moment";
//  import CurrencyInput from "./../vue-weblineindia-currency-input/src/component";
export default {
  name: "templates-RepeatableTemplateExecute",
  props: [
    "templateData",
    "entityRepeatableTemplateData",
    "baseWidth",
    "form",
    "isSubmit",
    "entitiesData",
    "isView",
    "getDefaultDateFormat",
    "singleCompanyTemplateCheckBoxArray",
    "max_value",
    "min_value",
    "templateRules",
    "templateRuleFields",
  ],
  mixins: [TemplateBuilderHelper],
  async mounted() {
    if (
      this.entityRepeatableTemplateData &&
      this.entityRepeatableTemplateData.default_display == "KANBAN"
    ) {
      this.isOn = true;
    }
    await Promise.all([this.setTemplateData(), this.getRepeatableIds()]);
    this.loading = false;
  },
  computed: {
    getTableStyles() {
      return "width:" + this.baseWidth + "px !important;";
    },
    isRequiredFiled() {
      return (column) => {
        let fields = JSON.parse(
          JSON.stringify(this.templateData.sections[0].fields)
        );
        fields = fields.map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }
          return e;
        });
        let fieldsInorder = [];
        fields.forEach((e) => {
          if (e) {
            if (e.type == "FIELD") {
              fieldsInorder.push(e);
            }
          }
        });

        // let fieldResult = this._.filter(fieldsInorder, function (o) {
        //   return o.label === column.label;
        // });
        let fieldResult = fieldsInorder.filter((o) => o.label === column.label);
        if (fieldResult && fieldResult.length) {
          let value =
            fieldResult &&
            fieldResult[0] &&
            fieldResult[0].validations &&
            fieldResult[0].validations.required
              ? true
              : false;
          return value;
        } else {
          return false;
        }

        // Check if the column.label is required or not
        // return !column.label || column.label.trim() === '';
      };
    },
    ...mapGetters("templatesData", [
      "getRepeatableTemplateIds",
      "getFileUploadURL",
    ]),
  },
  data() {
    return {
      newEntityData: {},
      kanban_index: -1,
      kanbanDialog: false,
      isOn: false,
      loading: false,
      hasLabel: true,
      tableHeaders: [],
      repeatableTemplateTitle: "",
      // entitiesData: [],
      lastFieldValueY: null,
      currentPageBaseWidth: 900,
      selectedRow: {},
      allFormData: [],
      isNewDataAdded: false,
      selectedRowIndex: null,
      isEditingData: false,
      repeatableIds: [],
      isEditingKanban: false,
      mappedTemplateData: {},
      hideFields: {},
      disabledFields: {},
      showTemplateFields: {},
    };
  },
  components: {
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute"),
    FileExecute: () => import("./formComponentsExecute/FileExecute"),
    CheckBoxExecute: () => import("./formComponentsExecute/CheckBoxExecute"),
    CurrencyExecute: () => import("./formComponentsExecute/CurrencyExecute"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute"),
    ListExecute: () => import("./formComponentsExecute/ListExecute"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () => import("./formComponentsExecute/DateRangeExecute"),
    CheckBoxGroupExecute: () =>
      import("./formComponentsExecute/CheckBoxGroupExecute"),
    //RadioButtonGroupExecute
    RadioButtonGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute"),
    RadioExecute: () => import("./formComponentsExecute/RadioExecute"),
    AutoIncrementExecute: () =>
      import("./formComponentsExecute/AutoIncrementExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute.vue"),
    QuestionExecute: () => import("./formComponentsExecute/QuestionExecute"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute"),
    YesOrNoExecute: () => import("./formComponentsExecute/YesOrNoExecute"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute"),
    // MultiSelectExecute: () =>
    //   import("./formComponentsExecute/MultiSelectExecute"),
    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute.vue"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute.vue"),
    DateExecute: () => import("./formComponentsExecute/DateExecute.vue"),
    DateTimeExecute: () =>
      import("./formComponentsExecute/DateTimeExecute.vue"),
    TimeRangeExecute: () =>
      import("./formComponentsExecute/TimeRangeExecute.vue"),
    EntityVariableExecute: () =>
      import("./formComponentsExecute/EntityVariableExecute"),
    AggregateFunctionExecute: () =>
      import("./formComponentsExecute/AggregateFunctionExecute"),
    ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute"),
    CalenderExecute: () =>
      import("./formComponentsExecute/CalenderExecute.vue"),
    IconExecute: () => import("./formComponentsExecute/IconExecute.vue"),
    RandomTextExecute: () =>
      import("./formComponentsExecute/RandomTextExecute.vue"),
  },
  methods: {
    FilterData(column) {
      return this.tableHeaders.filter(
        (e) =>
          e.input_type == "WEEKDAYS" &&
          e.allow_multiple &&
          e.label === column.label
      );
    },
    closeDialog() {
      this.kanbanDialog = false;
    },
    getColumnWidth(column) {
      if (column.input_type === "PHONE_COUNTRY_CODE") {
        return 350;
      } else if (column.input_type === "ENTITY") {
        return 220;
      } else if (column.input_type === "STAR_RATING") {
        return 180;
      } else if (
        column.input_type === "TIME_RANGE" ||
        column.input_type === "DATE_TIME_RANGE"
      ) {
        return 380;
      } else {
        return 150;
      }
    },
    checkIsDisabled(field, indexKey) {
      {
        {
          field, indexKey;
        }
      }
      if (
        this.templateRuleFields &&
        this.templateRuleFields.disabledFields &&
        this.templateRuleFields.disabledFields[indexKey]
      ) {
        return this.templateRuleFields.disabledFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkIsRequired(data) {
      if (data.allow_multiple) {
        if (data.min_value > 0) {
          return true;
        }
        return false;
      }
      return data?.validations?.required ? true : false;
    },
    checkhideFields(field, indexKey) {
      if (
        this.templateRuleFields.hideFields &&
        this.templateRuleFields.hideFields[indexKey]
      ) {
        return this.templateRuleFields.hideFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkIsNotFilled(field, data) {
      if (field.allow_multiple) {
        if (field.min_value > 0) {
          if (
            data &&
            data[field.key] &&
            typeof data[field.key] == "object" &&
            data[field.key].length >= field.min_value
          ) {
            return false;
          }
          return true;
        }
        return false;
      } else {
        if (data && data[field.key]) {
          return false;
        }
        return true;
      }
    },
    checkRequiredFields() {
      this.requiredFields = [];
      this.minValue = "";
      this.fileMinValue = "";
      this.fileMaxValue = "";
      let showError = false;
      let invalidError = false;
      let emailFormatError = false;
      let checkboxMinMaxCheck = false;
      let multiselectMinMaxCheck = false;
      let fileMinMaxCheck = false;
      let customErrorMessage = "";
      let reEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.hasRepeatableTemplate) {
        this.entitiesData.push(this.form);
      }
      this.entitiesData.forEach((data) => {
        this.fieldsData.forEach((field) => {
          if (field.input_type === "AUTO_INCREMENT_NUMBER") {
            //do nothing
          } else if (
            field.input_type == "WEEKDAYS" &&
            this.checkIsRequired(field) &&
            this.checkIsNotFilled(field, data)
          ) {
            if (field.allow_multiple) {
              customErrorMessage =
                "Please select " +
                field.min_value +
                " options for " +
                field.label +
                " field";
            }
            showError = true;
            this.requiredFields.push(field.label);
          } else if (
            field.validations &&
            field.validations.required &&
            // !data[field.key] &&
            ((!this.checkIsDisabled(field) &&
              !this.checkhideFields(field) &&
              !this.hasRepeatableTemplate) ||
              (this.hasRepeatableTemplate &&
                !this.checkFieldFillStatus(field, data)))
          ) {
            if (
              field.validations.type &&
              field.validations.type == "ARRAY" &&
              field.key &&
              data[field.key] &&
              !data[field.key].length
            ) {
              showError = true;
              this.requiredFields.push(field.label);
            } else if (field.key && !data[field.key]) {
              showError = true;
              this.requiredFields.push(field.label);
            }
          }

          if (
            this.templateData &&
            this.templateData.type == "STANDARD" &&
            this.templateData.slug == "contactdetails"
          ) {
            let NameRe = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].?\d|\d.?[A-Za-z]){1,}/;
            let PhoneRe =
              /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
            if (field.key == "first_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key].trim().length
              ) {
                customErrorMessage = "Invalid first name";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "last_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key].trim().length
              ) {
                customErrorMessage = "Invalid last name";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "phone_number") {
              if (
                field &&
                data[field.key] &&
                !PhoneRe.test(data[field.key]).replaceAll(" ", "")
              ) {
                customErrorMessage = "Invalid Phone Number";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "email") {
              if (
                data[field.key] &&
                data[field.key].toLowerCase() ==
                  this.getAuthenticatedUser.email.toLowerCase()
              ) {
                this.$message({
                  message: "You cannot add sender email!.",
                  type: "error",
                });
                data[field.key] = "";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                this.requiredFields.push(field.label);
              } else {
                if (
                  data[field.key] &&
                  (!data[field.key].length || !reEmail.test(data[field.key]))
                ) {
                  emailFormatError = true;
                  checkboxMinMaxCheck = false;
                  multiselectMinMaxCheck = false;
                  fileMinMaxCheck = false;
                  showError = true;
                  invalidError = true;
                  this.requiredFields.push(field.label);
                }
              }
            }
          }
          if (
            field.inputType == "FILE" &&
            field.validations.multiple &&
            data[field.key] &&
            data[field.key].files &&
            data[field.key].files.length &&
            !this.checkInBetween(
              data[field.key].files.length,
              field.validations.min_document_files,
              field.validations.max_document_files
            )
          ) {
            checkboxMinMaxCheck = false;
            multiselectMinMaxCheck = false;
            fileMinMaxCheck = true;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.fileMinValue = field.validations.min_document_files;
            this.fileMaxValue = field.validations.max_document_files;
          }
          if (
            field.inputType == "CHECKBOX_GROUP" &&
            data[field.key] &&
            data[field.key].length &&
            data[field.key].length < this.getMinValue(field, data)
          ) {
            checkboxMinMaxCheck = true;
            multiselectMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          } else if (
            field.inputType == "CHECKBOX_GROUP" &&
            (!data[field.key] || !data[field.key].length)
          ) {
            checkboxMinMaxCheck = true;
            multiselectMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          }
          if (
            field.inputType == "MULTI_SELECT" &&
            data[field.key] &&
            data[field.key].length &&
            data[field.key].length < this.getMinValue(field, data)
          ) {
            multiselectMinMaxCheck = true;
            checkboxMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          } else if (
            field.inputType == "MULTI_SELECT" &&
            (!data[field.key] || !data[field.key].length)
          ) {
            multiselectMinMaxCheck = true;
            checkboxMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          }
        });
      });
      return {
        showError,
        invalidError,
        emailFormatError,
        checkboxMinMaxCheck,
        multiselectMinMaxCheck,
        customErrorMessage,
        fileMinMaxCheck,
      };
    },
    checkFieldFillStatus(field, data) {
      return (
        this.checkRepeatableIsDisabled(field, data.indexKey) ||
        this.checkRepeatableFields(field, data.indexKey)
      );
    },
    rulesEventEmitter(indexKey) {
      if (indexKey) {
        let rowIndex = this.entitiesData.findIndex(
          (e) => e.indexKey === indexKey
        );

        if (rowIndex > -1) {
          this.applyRulesOnRepeatableData(
            this.tableHeaders,
            this.templateRules,
            rowIndex,
            indexKey
          );
        } else if (this.kanbanDialog) {
          this.applyRulesOnRepeatableData(
            this.tableHeaders,
            this.templateRules,
            0,
            indexKey,
            false,
            true
          );
        }
      }
    },
    async uploadFile(file) {
      file.forEach(async (el) => {
        await this.getFileUploadUrl(el);
        await this.uploadSelectedDocument(el.raw);
        if (this.getUploadedFileData) {
          return (
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key
          );
        } else {
          return "";
        }
      });
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };

      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        }
        // else {
        //   this.$message("Sorry ! Error while file uploading");
        // }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    async prepareFormData() {
      this.preparingFormDataLoading = true;
      this.loading = true;
      this.loading = "Preparing template data...";
      for (let [key, value] of Object.entries(this.form)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.form[key].path = path;
          this.form[key].new = false;
        }
      }
      this.loading = false;

      this.preparingFormDataLoading = false;
      return this.form;
    },
    emitUnsetEntityVariables(parent) {
      this.tableHeaders.forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.selectedRow, field.key, "");
        }
      });
    },
    emitDataToEntityVariables(data, parent, label) {
      if (label) {
        this.$set(this.selectedRow, parent.key + "/name", label);
      }
      this.tableHeaders.forEach((field) => {
        if (field.relationship_key == parent.key && data.entityData) {
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (
              data.entityData &&
              data.entityData[templateId] &&
              data.entityData[templateId][key]
            ) {
              value = data.entityData[templateId][key];
            }
            if (this.selectedRow[field.key]) {
              this.selectedRow[field.key] = value;
            } else {
              this.$set(this.selectedRow, field.key, value);
            }
          }
        }
      });
    },
    cancelChanges() {
      this.selectedRow = {};
      this.kanban_index = -1;
    },
    saveChanges() {
      if (this.kanban_index > -1) {
        this.entitiesData[this.kanban_index] = { ...this.selectedRow };
        this.kanban_index = -1;
      }
    },
    // kabanAdd() {
    // let checkData = this.checkRequiredFields();
    // if (checkData.showError) {
    //   if (checkData.customErrorMessage) {
    //     this.$notify.error({
    //       title: "Error",
    //       message: checkData.customErrorMessage
    //     });
    //   } else if (checkData.emailFormatError) {
    //     this.$notify.error({
    //       title: "Error",
    //       message: "There is an error in the email format. Please check"
    //     });
    //   } else if (checkData.checkboxMinMaxCheck) {
    //     this.$notify.error({
    //       title: "Error",
    //       message: `Please select options that are greater than or equal to '${this.minValue}', to meet the minimum selection requirement.`
    //     });
    //   } else if (checkData.fileMinMaxCheck) {
    //     this.$notify.error({
    //       title: "Error",
    //       message: `Please upload minimum '${this.fileMinValue}' files or maximum '${this.fileMaxValue}' files , to meet the minimum & maximum file(s) requirement.`
    //     });
    //   } else {
    //     this.$notify.error({
    //       title: "Error",
    //       message: "Please fill all required fields"
    //     });
    //   }
    // } else if (
    //   this.hasRepeatableTemplate &&
    //   this.entitiesData.length < this.min_value
    // ) {
    //   let name =
    //     this.entityRepeatableTemplateData &&
    //     this.entityRepeatableTemplateData.templateInfo &&
    //     this.entityRepeatableTemplateData.templateInfo.name
    //       ? this.entityRepeatableTemplateData.templateInfo.name
    //       : "template";
    //   this.$notify.error({
    //     title: "Error",
    //     message:
    //       "Please add " +
    //       name +
    //       " data " +
    //       (this.entitiesData.length > 0
    //         ? (this.min_value - this.entitiesData.length || 1) + " more times"
    //         : "")
    //   });
    // } else {
    //   this.checkForDuplicateData();
    // }
    kabanAdd() {
      if (this.max_value > this.entitiesData.length) {
        let key = (Math.random() + 1).toString(36).substring(7);
        let objKey = this.getRepeatableTemplateIds.find((key) => {
          let freeIndexKey = this.entitiesData.find(
            (e) => e && e.indexKey == key
          );
          if (!freeIndexKey) {
            return key;
          }
        });
        if (objKey) {
          key = objKey;
        }
        this.entitiesData.push({ ...this.selectedRow, ...{ indexKey: key } });
        this.kanbanDialog = false;
        this.$notify({
          title: "Success",
          message: "Data added successfully",
          type: "success",
        });
      } else {
        this.$notify({
          title: "Warning",
          message: "Max number of records already added. Can't add new data.",
          type: "warning",
        });
        this.kanbanDialog = false;
      }
    },
    editKanban(index) {
      this.kanban_index = index;
      const choosenElement = JSON.parse(
        JSON.stringify(this.entitiesData[index])
      );
      this.selectedRow = { ...{}, ...choosenElement };
      this.isEditingKanban = true;
    },
    deleteKanban(data) {
      this.$confirm("Are you sure to delete? ", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async () => {
        let index = this.entitiesData.findIndex(
          (e) => e.indexKey == data.indexKey
        );
        this.entitiesData.splice(index, 1);
        this.$notify({
          title: "Success",
          message: "Data deleted successfully",
          type: "success",
        });
      });
    },

    async kanbanpop() {
      if (this.max_value <= this.entitiesData.length) {
        this.$notify({
          title: "Warning",
          message: "Max number of records already added. Can't add new data.",
          type: "warning",
        });
        return;
      }
      this.tableHeaders.forEach((e) => {
        this.$set(this.selectedRow, e.key, "");
      });
      let key = (Math.random() + 1).toString(36).substring(7);
      if (
        this.getRepeatableTemplateIds &&
        this.getRepeatableTemplateIds.length
      ) {
        let objKey = this.getRepeatableTemplateIds.find((key) => {
          let freeIndexKey = this.entitiesData.find(
            (e) => e && e.indexKey == key
          );
          if (!freeIndexKey) {
            return key;
          }
        });
        if (objKey) {
          key = objKey;
        }
      }
      this.$set(this.selectedRow, "indexKey", key);
      // await this.resetSelectedRow();
      // this.selectedRow = { ...this.selectedRow, ...{ indexKey: key } };
      let entitiesData = [];
      entitiesData.push({ ...this.selectedRow, ...{ indexKey: key } });
      this.selectedRowIndex = key;
      this.applyRulesOnRepeatableData(
        this.tableHeaders,
        this.templateRules,
        0,
        key,
        false,
        true
      );
      this.kanbanDialog = true;
    },
    async getRepeatableIds() {
      this.repeatableIds = [];
      await this.$store.dispatch(
        "templatesData/fetchRepeatableTemplateIds",
        this.max_value
      );
    },
    resetSelectedRow() {
      if (this.selectedRowIndex != null) {
        let index = this.entitiesData.findIndex(
          (e) => e && e.indexKey == this.selectedRowIndex
        );
        if (index > -1) {
          let d = JSON.parse(JSON.stringify(this.selectedRow));
          this.entitiesData[index] = { ...d };
        }
      }
      this.selectedRow = {};
    },
    async addRowToDataTable() {
      this.isNewDataAdded = true;
      if (this.max_value > this.entitiesData.length) {
        let key = (Math.random() + 1).toString(36).substring(7);
        if (
          this.getRepeatableTemplateIds &&
          this.getRepeatableTemplateIds.length
        ) {
          let objKey = this.getRepeatableTemplateIds.find((key) => {
            let freeIndexKey = this.entitiesData.find(
              (e) => e && e.indexKey == key
            );
            if (!freeIndexKey) {
              return key;
            }
          });
          if (objKey) {
            key = objKey;
          }
        }

        // await setTimeout(async () => {
        //   await this.resetSelectedRow();
        // }, 100);
        this.resetSelectedRow();
        // this.selectedRow = { ...this.selectedRow, ...{ indexKey: key } };
        this.selectedRow["indexKey"] = key;
        this.entitiesData.push(this.selectedRow);
        this.selectedRowIndex = key;
        this.applyRulesOnRepeatableData(
          this.tableHeaders,
          this.templateRules,
          this.entitiesData.length - 1,
          key
        );
      } else {
        this.$notify({
          title: "Warning",
          message: "Max number of records already added. Can't add new data.",
          type: "warning",
        });
      }
    },
    async setTemplateData() {
      this.selectedRow = {};
      this.repeatableTemplateTitle =
        this.entityRepeatableTemplateData &&
        this.entityRepeatableTemplateData.senderAlias
          ? this.entityRepeatableTemplateData.senderAlias
          : this.templateData.name;
      this.tableHeaders = [];
      let fields = [];
      this.templateData.sections.forEach((section) => {
        if (section.fields && section.fields.length) {
          fields = [...fields, ...section.fields];
        }
      });
      fields = JSON.parse(JSON.stringify(this.templateData.sections[0].fields));
      fields = fields.map((e) => {
        if (e?.key && e.key.includes("#")) {
          e.key = e.key.split("#")[1];
        }
        return e;
      });
      let fieldsInorder = [];
      await fields.forEach((e) => {
        if (e) {
          if (e.height && e.y + e.height > this.lastFieldValueY) {
            this.lastFieldValueY = e.y + e.height;
          }
          if (e.type == "FIELD") {
            fieldsInorder.push(e);
          }
        }
      });
      this.tableHeaders = fieldsInorder.sort((a, b) => a.y - b.y);
      (this.tableHeaders || []).forEach((e) => {
        if (e?.key) {
          this.$set(this.selectedRow, e.key, "");
        }
      });
    },
    async hideEdit() {
      let index = -1;
      if (this.selectedRowIndex != null) {
        index = this.entitiesData.findIndex(
          (e) => e && e.indexKey == this.selectedRowIndex
        );
      }
      await (Object.keys(this.selectedRow) || []).forEach((key) => {
        if (this.selectedRowIndex != null && index != -1) {
          if (this.entitiesData[index] && key != "indexKey") {
            this.entitiesData[index][key] = this.selectedRow[key];
          }
        }
      });
      //   this.selectedRowIndex = null;
    },
    async onEdit(data) {
      this.resetSelectedRow();
      this.selectedRow = { ...data };
      this.selectedRowIndex = data.indexKey;
    },
    onDelete(data) {
      let index = this.entitiesData.findIndex(
        (e) => e.indexKey == data.indexKey
      );
      this.entitiesData.splice(index, 1);
    },
  },
};
</script>
<style>
.el-table .cell {
  word-break: break-word;
  overflow: hidden;
}
.el-table .td {
  word-wrap: break-word;
}
</style>
<style lang="scss" scoped>
.kanban-cards {
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: repeat(2, auto);
  max-height: 72vh;
  overflow-y: auto;
  scrollbar-width: thin;
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.kanban-card {
  border: 1px solid #cfd4d1;
}
.kanban-card-column {
  word-wrap: break-word;
  margin: 10px;
  padding: 5px;
}
.kanban-card-content {
  font-family: "system-ui";
  font-size: 16px;
}
.kanban-footer {
  border-top: 1px solid #cfd4d1;
  height: 2vh;
  padding: 2px;
  text-align: right;
}
.kanban-footer-button {
  color: #f73620;
}
.sidemenu {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}
.sidemenu::-webkit-scrollbar {
  width: 0em;
  height: 0.5em;
}
.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}
// above deep is to remove the doublescrolling in the repeatable templat tabel
</style>

<style>
#add-an-element-popup .el-dialog__body {
  max-height: 60vh;
  padding: 1em 1.5em;
  overflow-y: auto; /* or scroll */
  overflow-x: hidden;

  /* Reset styles for the webkit scrollbar in el-dialog__body */
  &::-webkit-scrollbar {
    width: initial;
    height: initial;
  }

  &::-webkit-scrollbar-thumb {
    width: initial;
    height: initial;
    border-radius: initial;
    background-clip: initial;
    border: initial;
  }

  /* Add the specific styles for the webkit scrollbar in el-dialog__body */
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 0px;
    border-radius: 10px;
    background-clip: padding-box;
    border: 10px solid rgba(0, 0, 0, 0.28);
  }
}

.vue-phone-number-input {
  height: 90px !important;
}
</style>
